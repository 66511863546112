import { Router, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { OpenIdConnectRoles } from 'aurelia-open-id-connect';
import { HttpClient } from 'aurelia-fetch-client';
import { OData } from 'utils/odata';
import { AlertModel } from 'alert-model';
import { autoinject } from 'aurelia-framework';
import './list.scss';
import { CustomerModel } from 'customer-model';
import { CurrentUser } from 'components/current-user/current-user';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class List {
    activeAlerts: AlertModel[];
    dismissedAlerts: AlertModel[];
    currentUser: CurrentUser;
    httpClient: HttpClient;
    customerId: string;
    activeCases: Case[];
    closedCases: Case[];
    stats: Stats = new Stats();
    profile: Profile;
    users: Profile[] = [];
    customer: CustomerModel;

    constructor(httpClient: HttpClient, currentUser: CurrentUser) {
        this.httpClient = httpClient;
        this.currentUser = currentUser;
    }

    async activate(params, rap, navigationInstruction) {
        params = cleanParamsObject(params);
        this.customerId = navigationInstruction.parentInstruction.params.id;
        await this.loadCustomer(this.customerId);
        await this.load(this.customerId);
        await this.loadStats(this.customerId);
    }

    async loadCustomer(id: string) {
        try {
            let response: any = await this.httpClient.get(`customers/${id}`);
            let json = await response.json();

            this.customer = <CustomerModel>json;
        } catch (error) {
            // this.errorService.showErrorDialog(error.message);
            // this.router.navigateToRoute('customers', {});
        }
    }

    async load(id: string) {
        this.profile = await this.currentUser.get();
    }

    async loadStats(id: string) {
        let response = await this.httpClient.get(`customers/${id}/stats`);
        let json = await response.json();

        this.stats = <Stats>json;
    }
}

class Case {}

class Stats {
    alerts: number = 0;
    unassignedAlerts: number = 0;
    cases: number = 0;
    falsePositives: number = 0;
}

class Profile {}
