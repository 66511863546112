import { bindable } from 'aurelia-framework';
import { DocumentModel } from 'document-model';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-dependency-injection';
import { UserModel } from 'user-model';
import { ConfirmService } from 'components/confirmation/confirm-service';
import { ErrorService } from 'error/error-service';
import { PLATFORM } from 'aurelia-pal';
import { CurrentUser } from 'components/current-user/current-user';

let unqiueFieldId: number = 0;

@autoinject
export class DocumentsList {
    documents: DocumentModel[] = [];
    @bindable editable: boolean = true;
    @bindable context: string;
    @bindable id: string;
    files: FileList;
    fileElement: any;
    httpClient: HttpClient;
    profile: UserModel = new UserModel();
    confirmService: ConfirmService;
    isHighlighted: boolean = false;
    currentUser: CurrentUser;
    orderby: string = '';

    maximumFileSize: number = 10;
    errorService: ErrorService;
    fieldId: string = 'docs-field-' + unqiueFieldId++;

    constructor(
        httpClient: HttpClient,
        confirmService: ConfirmService,
        errorService: ErrorService,
        currentUser: CurrentUser,
    ) {
        this.httpClient = httpClient;
        this.confirmService = confirmService;
        this.errorService = errorService;
        this.currentUser = currentUser;
    }

    async attached(): Promise<void> {
        this.profile = await this.currentUser.get();
        this.fileElement.value = '';

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
            PLATFORM.addEventListener(eventName, this.preventDefaults, false);
        });

        const response = await this.httpClient.get(`${this.context}/${this.id}/documents`);
        const data = await response.json();

        this.documents = <DocumentModel[]>data.list;
    }

    detached(): void {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
            PLATFORM.removeEventListener(eventName, this.preventDefaults, false);
        });
    }

    highlight(): void {
        this.isHighlighted = true;
    }

    unhighlight(): void {
        this.isHighlighted = false;
    }

    preventDefaults(e): void {
        e.preventDefault();
        e.stopPropagation();
    }

    async handleDrop(e): Promise<void> {
        const dt = e.dataTransfer;
        const files = [...dt.files];

        this.unhighlight();
        for (const file of files) {
            await this.uploadFile(file);
        }
    }

    async uploadFile(file: File): Promise<void> {
        if (file.size > this.maximumFileSize * 1_048_576) {
            this.errorService.showError(
                'File size limit reached',
                `The attached file exceeds our file size limit (${this.maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
            );
            return;
        }

        let form = new FormData();

        form.append('file', file);
        const response = await this.httpClient.post(`${this.context}/${this.id}/documents`, form);
        const id = (await response.json()).documentId;
        let model = new DocumentModel();

        model.contentType = file.type;
        model.documentId = id;
        model.name = file.name;
        model.uploadedBy = this.profile;
        model.uploadedTime = new Date();

        this.documents.push(model);
    }

    async fileSelected(): Promise<void> {
        if (this.files.length == 0) {
            return;
        }

        for (let i = 0; i < this.files.length; i++) {
            await this.uploadFile(this.files[i]);
        }

        this.fileElement.value = '';
    }

    async remove(document: DocumentModel): Promise<void> {
        this.confirmService.confirm(
            'Deleting document',
            `Are you sure you want to delete the file: ${document.name}. This action cannot be undone.`,
            async () => {
                await this.httpClient.delete(`${this.context}/${this.id}/documents/${document.documentId}`, '{}');

                const index = this.documents.findIndex((x) => x.documentId == document.documentId);

                this.documents.splice(index, 1);
            },
        );
    }

    async download(document: DocumentModel): Promise<void> {
        const response: any = await this.httpClient.get(`${this.context}/${this.id}/documents/${document.documentId}`);
        const content = await response.blob();
        const a = window.document.createElement('a');

        a.setAttribute('style', 'display:none;');
        window.document.body.appendChild(a);
        a.download = document.name;
        const url = URL.createObjectURL(content);
        a.href = url;
        a.target = '_blank';
        a.click();
        window.document.body.removeChild(a);

        URL.revokeObjectURL(url);
    }

    async sort(field: string, order: string): Promise<void> {
        this.orderby = field;

        this.documents.sort((a, b) => {
            const x = a[field],
                y = b[field];

            const sortOrder = order === 'asc' ? 1 : -1;
            const so = x.localeCompare(y) * sortOrder;

            return so;
        });
    }
}
