import { LitElement, TemplateResult, css, html } from 'lit';
import { consume, provide } from '@lit/context';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { Task } from '@lit/task';
import { styles } from 'pli/styles';
import { customerType } from 'schema/customer-details-schema';

import 'pli/pli-text';
import 'pli/pli-tab-navigation';
import 'pli/pli-badge';
import { PliTabNavigationEvent } from 'pli/pli-tab-navigation';
import { Router, routerContext } from 'context/router-context';
import { customerDetailsContext } from 'context/details/customer-details-context';
import './components/customer-details-investigations';
import './components/customer-details-profile';
import './components/customer-details-documents';
import './components/customer-details-comments';
import { z } from 'zod';
import 'shared/transaction/transaction-history-area';
import { getCustomer, GetCustomer_Result } from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

const AVAILABLE_TABS = ['Investigations', 'Profile', 'TransactionHistory', 'Documents', 'Comments'] as const;
const tabs = z.enum(AVAILABLE_TABS);
type DetailsTab = z.infer<typeof tabs>;

@customElement('customer-details2')
class CustomerDetails extends LitElement {
    static styles = [styles.grid, styles.flex, css``];
    @property({ type: String, reflect: true })
    customerId?: string;
    @consume({ context: routerContext })
    router?: Router;

    @provide({ context: customerDetailsContext })
    customerDetails: GetCustomer_Result | null = null;

    @consume({ context: errorDialogContext })
    errorDialog!: ErrorDialogController;

    _hash: string | null = null;

    _task = new Task(this, {
        task: async ([]) => {
            const { data, error } = await getCustomer({ path: { id: this.customerId ?? '' } });
            if (error || !data) {
                return this.errorDialog.showError({ title: 'Could not get customer information' });
            }

            this.customerDetails = data;
        },
        args: () => [] as const,
    });

    _tabs: { key: DetailsTab; title: string; hash: string }[] = [
        {
            key: 'Investigations',
            title: 'Investigations',
            hash: '#investigations',
        },
        {
            key: 'Profile',
            title: 'Profile',
            hash: '#profile',
        },
        {
            key: 'TransactionHistory',
            title: 'Transaction history',
            hash: '#transaction-history',
        },
        {
            key: 'Documents',
            title: 'Documents',
            hash: '#documents',
        },
        {
            key: 'Comments',
            title: 'Comments',
            hash: '#notes',
        },
    ];

    connectedCallback(): void {
        super.connectedCallback();
        this.setHash();
    }

    onNavigate = (event: PliTabNavigationEvent) => {
        const { current } = event.detail;
        const { hash } = this._tabs.find((tab) => tab.title === current) ?? this._tabs[0];
        const { pathname } = window.location;
        const url = `${pathname}${hash}`;
        this.setHash(hash);

        this.router?.navigate(url, {
            replace: true,
            trigger: true,
        });
        this.requestUpdate();
    };

    private setHash(hash = window.location.hash) {
        this._hash = hash;
    }

    protected render(): unknown {
        const { _hash, onNavigate, _tabs } = this;

        const tabTitles = _tabs.map((t) => t.title);
        const currentTab = _tabs.find((tab) => tab.hash === _hash) ?? _tabs[0];
        const currentItem = currentTab.title;

        if (!this.customerDetails) {
            return null;
        }

        const componentMap: Record<DetailsTab, TemplateResult> = {
            Investigations: html`<div>
                <slot name="investigations"></slot>
            </div>`,
            Profile: html`<customer-details-profile></customer-details-profile>`,
            TransactionHistory: html`<transaction-history-area .customerId="${this.customerDetails.customerId}">
                <pli-text as="h2" variant="h2" slot="header">Transaction History</pli-text>
            </transaction-history-area>`,
            Documents: html`<customer-details-documents
                .customerId="${this.customerDetails.customerId}"
            ></customer-details-documents>`,
            Comments: html`<customer-details-comments
                .customerId="${this.customerDetails.customerId}"
            ></customer-details-comments>`,
        };

        return html` <div class="grid-vertical gap-3">
            <div class="flex items-center gap-1">
                <pli-text as="h1" variant="h1">${this.customerDetails.name}</pli-text> ${this.renderBadge()}
            </div>
            <pli-tab-navigation
                @navigate="${onNavigate}"
                currentItem="${currentItem}"
                .items="${tabTitles}"
            ></pli-tab-navigation>
            ${when(this.customerDetails, () => componentMap[currentTab.key])}
        </div>`;
    }

    renderBadge(): TemplateResult | undefined {
        const { customerDetails } = this;

        if (!customerDetails) return;

        if (customerDetails.customerType === customerType.Enum.Person)
            return html`<pli-badge color="purple" text="Person" icon="person"></pli-badge>`;

        if (customerDetails.customerType === customerType.Enum.Organization)
            return html`<pli-badge color="purple" text="Organization" icon="briefcase"></pli-badge>`;
    }
}
