import { DOM } from 'aurelia-framework';
import { Renderer, DialogController, MouseEventType, ActionKey } from 'aurelia-dialog';
import { transient } from 'aurelia-dependency-injection';

const containerTagName = 'ux-dialog-container';
const overlayTagName = 'ux-dialog-overlay';

export const transitionEvent = (() => {
    let transition: string | undefined;
    return (): string => {
        if (transition) {
            return transition;
        }
        const el = DOM.createElement('fakeelement') as HTMLElement;
        const transitions: { [key: string]: string } = {
            transition: 'transitionend',
            OTransition: 'oTransitionEnd',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
        };
        for (let t in transitions) {
            if ((el.style as any)[t] !== undefined) {
                transition = transitions[t];
                return transition;
            }
        }
        return '';
    };
})();

export const hasTransition = (() => {
    const unprefixedName: any = 'transitionDuration';
    const prefixedNames = ['webkitTransitionDuration', 'oTransitionDuration'];
    let el: HTMLElement;
    let transitionDurationName: string | undefined;
    return (element: Element) => {
        if (!el) {
            el = DOM.createElement('fakeelement') as HTMLElement;
            if (unprefixedName in el.style) {
                transitionDurationName = unprefixedName;
            } else {
                transitionDurationName = prefixedNames.find((prefixed) => prefixed in el.style);
            }
        }
        return (
            !!transitionDurationName &&
            !!(DOM.getComputedStyle(element) as any)[transitionDurationName]
                .split(',')
                .find((duration: string) => !!parseFloat(duration))
        );
    };
})();

let body: HTMLBodyElement;

function getActionKey(e: KeyboardEvent): ActionKey | undefined {
    if ((e.code || e.key) === 'Escape' || e.keyCode === 27) {
        return 'Escape';
    }
    if ((e.code || e.key) === 'Enter' || e.keyCode === 13) {
        return 'Enter';
    }
    return undefined;
}

export class CustomDialogRenderer implements Renderer {
    public static dialogControllers: DialogController[] = [];

    public static keyboardEventHandler(e: KeyboardEvent) {
        const key = getActionKey(e);
        if (!key) {
            return;
        }
        const top = CustomDialogRenderer.dialogControllers[CustomDialogRenderer.dialogControllers.length - 1];
        if (!top || !top.settings.keyboard) {
            return;
        }
        const keyboard = top.settings.keyboard;
        if (
            key === 'Escape' &&
            (keyboard === true || keyboard === key || (Array.isArray(keyboard) && keyboard.indexOf(key) > -1))
        ) {
            top.cancel();
        } else if (key === 'Enter' && (keyboard === key || (Array.isArray(keyboard) && keyboard.indexOf(key) > -1))) {
            top.ok();
        }
    }

    public static trackController(dialogController: DialogController): void {
        const trackedDialogControllers = CustomDialogRenderer.dialogControllers;
        if (!trackedDialogControllers.length) {
            DOM.addEventListener(
                dialogController.settings.keyEvent || 'keyup',
                CustomDialogRenderer.keyboardEventHandler,
                false,
            );
        }
        trackedDialogControllers.push(dialogController);
    }

    public static untrackController(dialogController: DialogController): void {
        const trackedDialogControllers = CustomDialogRenderer.dialogControllers;
        const i = trackedDialogControllers.indexOf(dialogController);
        if (i !== -1) {
            trackedDialogControllers.splice(i, 1);
        }
        if (!trackedDialogControllers.length) {
            DOM.removeEventListener(
                dialogController.settings.keyEvent || 'keyup',
                CustomDialogRenderer.keyboardEventHandler,
                false,
            );
        }
    }

    private stopPropagation: (e: MouseEvent & { _aureliaDialogHostClicked: boolean }) => void;
    private closeDialogClick: (e: MouseEvent & { _aureliaDialogHostClicked: boolean }) => void;
    private trapMethod: (e: any & { firstElement: HTMLElement; lastElement: HTMLElement }) => void;
    public dialogContainer: HTMLElement;
    public dialogOverlay: HTMLElement;
    public lastActiveElement: HTMLElement;
    public host: Element;
    public anchor: Element;

    private getOwnElements(parent: Element, selector: string): Element[] {
        const elements = parent.querySelectorAll(selector);
        const own: Element[] = [];
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].parentElement === parent) {
                own.push(elements[i]);
            }
        }
        return own;
    }

    private attach(dialogController: DialogController): void {
        if (dialogController.settings.restoreFocus) {
            this.lastActiveElement = DOM.activeElement as HTMLElement;
        }

        const spacingWrapper = DOM.createElement('div');
        spacingWrapper.appendChild(this.anchor);

        const dialogContainer = (this.dialogContainer = DOM.createElement(containerTagName) as HTMLElement);
        dialogContainer.appendChild(spacingWrapper);

        const dialogOverlay = (this.dialogOverlay = DOM.createElement(overlayTagName) as HTMLElement);
        const zIndex =
            typeof dialogController.settings.startingZIndex === 'number'
                ? dialogController.settings.startingZIndex + ''
                : 'auto';

        dialogOverlay.style.zIndex = zIndex;
        dialogContainer.style.zIndex = zIndex;

        const host = this.host;
        const lastContainer = this.getOwnElements(host, containerTagName).pop();

        if (lastContainer && lastContainer.parentElement) {
            host.insertBefore(dialogContainer, lastContainer.nextSibling);
            host.insertBefore(dialogOverlay, lastContainer.nextSibling);
        } else {
            host.insertBefore(dialogContainer, host.firstChild);
            host.insertBefore(dialogOverlay, host.firstChild);
        }
        dialogController.controller.attached();
        host.classList.add('ux-dialog-open');
    }

    private detach(dialogController: DialogController): void {
        const host = this.host;
        body.style.paddingRight = '';
        this.dialogContainer.removeEventListener('keydown', this.trapMethod);
        host.removeChild(this.dialogOverlay);
        host.removeChild(this.dialogContainer);
        dialogController.controller.detached();
        if (!CustomDialogRenderer.dialogControllers.length) {
            host.classList.remove('ux-dialog-open');
        }
        if (dialogController.settings.restoreFocus) {
            dialogController.settings.restoreFocus(this.lastActiveElement);
        }
    }

    private setAsActive(): void {
        this.dialogOverlay.classList.add('active');
        this.dialogContainer.classList.add('active');
        this.setFocusToElement();
        this.trapTabFocus();
    }

    private trapTabFocus(): void {
        const focusableEls = this.dialogContainer.querySelectorAll(
            'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
        );
        const firstFocusableElement = focusableEls[0] as HTMLElement;
        const lastFocusableElement = focusableEls[focusableEls.length - 1] as HTMLElement;
        this.trapMethod = (event) => this.tabInTrap(event, firstFocusableElement, lastFocusableElement);
        this.dialogContainer.addEventListener('keydown', this.trapMethod);
    }

    private tabInTrap(e: any, firstElement: HTMLElement, lastElement: HTMLElement): void {
        const KEYCODE_TAB = 9;
        const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
        if (!isTabPressed) return;

        if (e.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstElement) {
                lastElement.focus();
                e.preventDefault();
            }
        } /* tab */ else {
            if (document.activeElement === lastElement) {
                firstElement.focus();
                e.preventDefault();
            }
        }
    }

    private setFocusToElement(): void {
        const inputs = this.dialogContainer.getElementsByTagName('input');
        if (inputs.length > 0) {
            inputs[0].focus();
            return;
        }
        const textareas = this.dialogContainer.getElementsByTagName('textarea');
        if (textareas.length > 0) {
            textareas[0].focus();
            return;
        }
        const buttons = this.dialogContainer.getElementsByTagName('button');
        if (buttons.length > 0) {
            buttons[0].focus();
        }
    }

    private setAsInactive(): void {
        this.dialogOverlay.classList.remove('active');
        this.dialogContainer.classList.remove('active');
    }

    private scrollBarWidth(): number {
        return window.innerWidth - document.body.offsetWidth;
    }

    private setupEventHandling(dialogController: DialogController): void {
        this.stopPropagation = (e) => {
            e._aureliaDialogHostClicked = true;
        };
        this.closeDialogClick = (e) => {
            if (dialogController.settings.overlayDismiss && !e._aureliaDialogHostClicked) {
                dialogController.cancel();
            }
        };
        const mouseEvent: MouseEventType = dialogController.settings.mouseEvent || 'click';
        this.dialogContainer.addEventListener(mouseEvent, this.closeDialogClick);
        this.anchor.addEventListener(mouseEvent, this.stopPropagation);
    }

    private clearEventHandling(dialogController: DialogController): void {
        const mouseEvent: MouseEventType = dialogController.settings.mouseEvent || 'click';
        this.dialogContainer.removeEventListener(mouseEvent, this.closeDialogClick);
        this.anchor.removeEventListener(mouseEvent, this.stopPropagation);
    }

    private centerDialog() {
        this.dialogContainer.style.display = 'flex';
        this.dialogContainer.style.justifyContent = 'center';
        this.dialogContainer.style.alignItems = 'center';
    }

    private awaitTransition(setActiveInactive: () => void, ignore: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            // tslint:disable-next-line:no-this-assignment
            const renderer = this;
            const eventName = transitionEvent();
            function onTransitionEnd(e: TransitionEvent): void {
                if (e.target !== renderer.dialogContainer) {
                    return;
                }
                renderer.dialogContainer.removeEventListener(eventName, onTransitionEnd);
                resolve();
            }

            if (ignore || !hasTransition(this.dialogContainer)) {
                resolve();
            } else {
                this.dialogContainer.addEventListener(eventName, onTransitionEnd);
            }
            setActiveInactive();
        });
    }

    public getDialogContainer(): Element {
        return this.anchor || (this.anchor = DOM.createElement('div'));
    }

    public showDialog(dialogController: DialogController): Promise<void> {
        if (!body) {
            body = DOM.querySelector('body') as HTMLBodyElement;
        }
        body.style.paddingRight = this.scrollBarWidth() + 'px';
        if (dialogController.settings.host) {
            this.host = dialogController.settings.host;
        } else {
            this.host = body;
        }
        const settings = dialogController.settings;
        this.attach(dialogController);

        if (typeof settings.position === 'function') {
            settings.position(this.dialogContainer, this.dialogOverlay);
        } else if (!settings.centerHorizontalOnly) {
            this.centerDialog();
        }

        CustomDialogRenderer.trackController(dialogController);
        this.setupEventHandling(dialogController);
        return this.awaitTransition(() => this.setAsActive(), dialogController.settings.ignoreTransitions as boolean);
    }

    public hideDialog(dialogController: DialogController) {
        this.clearEventHandling(dialogController);
        CustomDialogRenderer.untrackController(dialogController);
        return this.awaitTransition(
            () => this.setAsInactive(),
            dialogController.settings.ignoreTransitions as boolean,
        ).then(() => {
            this.detach(dialogController);
        });
    }
}

// avoid unnecessary code
transient()(CustomDialogRenderer);

export { CustomDialogRenderer as UxDialogRenderer };
