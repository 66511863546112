import { UserModel } from 'user-model';

export class CustomerNameValueConverter {
    toView(value: UserModel) {
        if (value === null || value === undefined) {
            return '';
        }

        if (value.firstName === null || value.lastName === null) {
            return value.userId;
        }

        return `${value.firstName} ${value.lastName}`;
    }
}
