import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../pli/pli-card';
import '../../../pli/pli-text';
import '../../../pli/pli-button';
import '../../../pli/pli-user-bubble';
import '../../../pli/pli-drag-drop';

import { styles } from 'pli/styles';
import { defineHeaderItems } from 'pli/pli-table';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { downloadContent } from 'utils/download-content';
import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { FileUpdateEvent } from '../../../pli/pli-drag-drop';
import { UserModel } from 'user-model';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import {
    archiveCustomerDocument,
    CustomerDocument,
    downloadCustomerDocument,
    GetCustomer_Result,
    getCustomerDocumentList,
    GetCustomerDocumentListResponse,
    uploadCustomerDocument,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../../pli/pli-pager';
import { when } from 'lit/directives/when.js';

@customElement('customer-details-documents')
class CustomerDetailsDocuments extends LitElement {
    static styles = [styles.grid, styles.flex, styles.divider, styles.padding, css``];

    @property()
    customerId!: GetCustomer_Result['customerId'];

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @consume({ context: errorDialogContext })
    errorDialog?: ErrorDialogController;

    @state()
    _data: GetCustomerDocumentListResponse | null = null;

    _task = new Task(this, {
        task: async ([id]) => {
            const { error, data } = await getCustomerDocumentList({
                path: {
                    id,
                },
            });

            if (error) {
                return this.errorDialog?.showError({ title: 'Could not get documents for customer' });
            }

            if (!data) {
                return;
            }

            this._data = data;
        },
        args: () => [this.customerId] as const,
        autoRun: false,
    });

    _profile = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._task.run();

        if (!this.currentUser) {
            return;
        }

        this._profile = await this.currentUser.get();
    }

    downloadDocument = async (document: CustomerDocument) => {
        if (!document.documentId) {
            return;
        }

        const { data, error } = await downloadCustomerDocument({
            path: { documentId: document.documentId, id: this.customerId },
        });

        if (error) {
            return this.errorDialog?.showError({ title: 'Could not download document' });
        }

        if (!data) {
            return;
        }

        downloadContent(data, document.name ?? '');
    };

    onFilesUpdate = async (event: FileUpdateEvent) => {
        const { value } = event.detail;
        for (const file of value) {
            let form = new FormData();
            form.append('file', file);

            const { data, error } = await uploadCustomerDocument({
                path: { id: this.customerId },

                body: { file },
            });

            if (error) {
                return this.errorDialog?.showError({
                    title: `Upload error for file: ${file.name}`,
                });
            }

            if (!data) {
                return;
            }

            this._task.run();
        }
    };

    onFilesFail = (event: FileUpdateEvent) => {
        const { maximumFileSize } = event.detail;
        this.errorDialog?.showError({
            title: 'File size limit reached',
            errorMessage: `Attached file(s) exceeds our file size limit (${maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
        });
    };

    _renderRemoveDocumentDialog = (item: CustomerDocument) => {
        const handleRemoveDocument = async (document: CustomerDocument) => {
            if (!document.documentId) {
                return;
            }

            const { error } = await archiveCustomerDocument({
                path: { id: this.customerId, documentId: document.documentId },
                parseAs: 'stream',
            });

            if (error) {
                return this.errorDialog?.showError({ title: 'Could not remove document' });
            }

            this._task.run();
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button
                    class="flex justify-end items-center"
                    slot="open-button"
                    variant="destructive"
                    width="hug-content"
                    ><pli-icon name="trash" slot="icon-left"></pli-icon>Remove</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Deleting document</pli-text>
                    <p>Are you sure you want to delete the file: ${item.name}? This action cannot be undone.</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleRemoveDocument(item)}"
                    variant="destructive"
                    slot="primary-button"
                    >Remove</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    render() {
        const { downloadDocument, _data, onFilesUpdate, onFilesFail } = this;
        const headerItems = defineHeaderItems({
            Name: {
                sortField: 'Name',
                columnSpan: 3,
            },
            Date: {
                sortField: null,
                columnSpan: 3,
            },
            'Created by': {
                sortField: null,
                columnSpan: 3,
            },
            '': {
                sortField: null,
                columnSpan: 3,
            },
        });

        const renderItem = (item: CustomerDocument): TemplateResult =>
            html` <tr>
                <td>
                    <pli-button variant="text" .onClick="${() => downloadDocument(item)}">
                        <pli-icon slot="icon-left" name="file-earmark-image"></pli-icon>
                        ${item.name}
                    </pli-button>
                </td>
                <td>${item.uploadedTime ? dateTimeFormatter(item.uploadedTime.toString()) : null}</td>
                <td>
                    <div class="flex items-center gap-1">
                        <div>
                            <pli-user-bubble
                                .userId="${item.uploadedBy?.userId ?? ''}"
                                .firstName="${item.uploadedBy?.firstName ?? ''}"
                                .lastName="${item.uploadedBy?.lastName ?? ''}"
                            ></pli-user-bubble>
                        </div>
                        <pli-text
                            >${nameFormatter({
                                firstName: item.uploadedBy?.firstName ?? '',
                                lastName: item.uploadedBy?.lastName ?? '',
                                userId: item.uploadedBy?.userId ?? '',
                            })}</pli-text
                        >
                    </div>
                </td>
                <td>
                    <div class="flex justify-end items-center">${this._renderRemoveDocumentDialog(item)}</div>
                </td>
            </tr>`;

        if (!_data) {
            return null;
        }

        return html`<pli-card>
            <div class="grid-vertical gap-1">
                <div class="grid-vertical gap-1 divider-y">
                    <div class="grid-vertical gap-1">
                        <pli-text as="h2" variant="h2">Documents</pli-text>
                        <pli-text as="p"
                            >Attach, view and download any file you need to document your investigation
                        </pli-text>
                    </div>

                    ${when(
                        _data?.list?.length,
                        () => html`
                            <pli-pager .items="${_data.list ?? []}">
                                <pli-table
                                    .headerItems="${headerItems}"
                                    .items="${_data?.list ?? []}"
                                    .renderTemplate="${renderItem}"
                                ></pli-table>
                            </pli-pager>
                        `,
                        () =>
                            html`<div class="py-4">
                                <div class="grid">
                                    <div class="col-span-6 col-start-4 grid-vertical gap-05">
                                        <pli-text as="p" variant="h4">No files or documents yet </pli-text>
                                        <pli-text as="p"
                                            >However, its very easy to add new ones (Maximum file size: 10 MB)
                                        </pli-text>
                                    </div>
                                </div>
                            </div>`,
                    )}
                </div>
                <pli-drag-drop @filesUpdate="${onFilesUpdate}" @filesFail="${onFilesFail}"></pli-drag-drop>
            </div>
        </pli-card>`;
    }
}
