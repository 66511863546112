export class PersonalInformation {
    public email: string;
    public mobileNumber: string;
    public gender: string;
    public birthdate: string;
    public nationality: string;
    public residence: string;
}

export class BankInformation {
    public bank: string;
    public account: string;
    public iban: string;
}

export class IncomeInformation {
    public source: string;
    public monthly: string;
}

class Alert {
    public value: string;
}

class Case {
    public value: string;
}

export class CustomerModel {
    public customerId: string;
    public dateTime: string;
    public firstName: string;
    public lastName: string;
    public identification: string;
    public personalInformation: PersonalInformation;
    public bankInformation: BankInformation;
    public incomeInformation: IncomeInformation;
    public alerts: Alert[];
    public cases: Case[];
}
