import { consume } from '@lit/context';
import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { customerDetailsContext } from 'context/details/customer-details-context';
import { Task } from '@lit/task';
import { styles } from 'pli/styles';

import '../../../pli/pli-text';
import '../../../pli/pli-card';
import '../../../pli/pli-tab-navigation';
import '../../../pli/pli-table';
import '../../../pli/pli-status-label';
import '../../../pli/pli-user-bubble';
import '../../../pli/pli-user-bubble-list';
import '../../../pli/pli-pagination';

import { PliTableSortEvent, defineHeaderItems } from '../../../pli/pli-table';
import { customerCaseSortableFields } from 'schema/customer-case-schema';

import { dateTimeFormatter } from 'utils/datetime-formatter';
import { PliTabNavigationEvent } from '../../../pli/pli-tab-navigation';
import { customerAlertSortableFields } from 'schema/customer-alert-schema';
import { PliPaginationChangeEvent } from '../../../pli/pli-pagination';
import { SortableOrder } from 'schema/pagination/pagination-schema';
import {
    AlertStatus,
    CaseStatus,
    GetCustomer_Result,
    getCustomerAlertList,
    GetCustomerAlertList_ItemResult,
    GetCustomerAlertList_SortField,
    GetCustomerAlertListData,
    GetCustomerAlertListResponse,
    getCustomerCaseList,
    GetCustomerCaseList_ItemResult,
    GetCustomerCaseList_SortField,
    GetCustomerCaseListData,
    GetCustomerCaseListResponse,
    getCustomerStats,
    GetCustomerStats_Result,
    ListResultOfItemResult8,
    ListResultOfItemResult9,
} from 'tms-client';
import '../../../pli/pli-pager';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import { PliUserBubbleProps } from '../../../pli/pli-user-bubble';

@customElement('customer-details-investigations')
class CustomerDetailsInvestigations extends LitElement {
    static styles = [styles.grid, styles.flex];

    @consume({ context: customerDetailsContext, subscribe: true })
    customerDetails: GetCustomer_Result | null = null;

    render() {
        if (!this.customerDetails) {
            return null;
        }

        return html`<div class="grid-vertical gap-3">
            <customer-details-investigations-statistics
                .customerId="${this.customerDetails.customerId}"
            ></customer-details-investigations-statistics>
            <customer-details-investigations-cases
                .customerId="${this.customerDetails.customerId}"
            ></customer-details-investigations-cases>
            <customer-details-investigations-alerts
                .customerId="${this.customerDetails.customerId}"
            ></customer-details-investigations-alerts>
        </div>`;
    }
}

@customElement('customer-details-investigations-statistics')
class CustomerDetailsStatistics extends LitElement {
    static styles = [styles.grid, styles.flex, styles.divider, styles.padding];

    @property()
    customerId: GetCustomer_Result['customerId'] = '';

    @consume({ context: errorDialogContext, subscribe: true })
    errorDialog!: ErrorDialogController;

    _statistics: GetCustomerStats_Result | undefined;

    _task = new Task(this, {
        task: async ([]) => {
            const { data, error } = await getCustomerStats({ path: { id: this.customerId } });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get customer statistics' });
            }

            this._statistics = data;
        },
        args: () => [] as const,
    });
    render() {
        if (!this._statistics) {
            return;
        }

        const { alerts, unassignedAlerts, cases, falsePositives } = this._statistics;

        return html`<div class="grid">
            <div class="col-span-7">
                <pli-card padding="collapsed" bordertype="default" background="none" boxshadow="none">
                    <div class="flex divider-x">
                        <div class="flex-1 grid-vertical gap-1 py-1-5 px-1">
                            <pli-text><strong>Total alerts</strong></pli-text>
                            <pli-text variant="h2" as="p">${alerts}</pli-text>
                        </div>
                        <div class="flex-1 grid-vertical gap-1 py-1-5 px-1">
                            <pli-text><strong>Unassigned alerts</strong></pli-text>
                            <pli-text variant="h2" as="p">${unassignedAlerts}</pli-text>
                        </div>
                        <div class="flex-1 grid-vertical gap-1 py-1-5 px-1">
                            <pli-text><strong>Total Cases created</strong></pli-text>
                            <pli-text variant="h2" as="p">${cases}</pli-text>
                        </div>
                        <div class="flex-1 grid-vertical gap-1 py-1-5 px-1">
                            <pli-text><strong>Total False positives</strong></pli-text>
                            <pli-text variant="h2" as="p">${falsePositives}</pli-text>
                        </div>
                    </div>
                </pli-card>
            </div>
        </div>`;
    }
}

const casesNavigationItems = ['Active cases', 'Case history'] as const;
type CaseNavigationItem = (typeof casesNavigationItems)[number];

@customElement('customer-details-investigations-cases')
class CustomerDetailsInvestigationsCases extends LitElement {
    static styles = [styles.grid, styles.flex, css``];

    @property()
    customerId: GetCustomer_Result['customerId'] = '';

    @consume({ context: errorDialogContext })
    errorDialog!: ErrorDialogController;

    _data: ListResultOfItemResult9 | null = null;
    _defaultStatuses: CaseStatus[] = ['Investigating', 'Reporting'];
    _historyStatuses: CaseStatus[] = ['Reported', 'Resolved'];
    _navigationItems = casesNavigationItems;
    _items: GetCustomerCaseListResponse['list'] = [];

    @state()
    _statuses: CaseStatus[] = [...this._defaultStatuses];
    @state()
    _page = 1;
    @state()
    _total = 1;
    @state()
    _sortField: GetCustomerCaseList_SortField = 'Created';
    @state()
    _sortOrder: SortableOrder | null = null;

    _task = new Task(this, {
        task: async ([page, sortField, sortOrder, statuses]) => {
            const query: GetCustomerCaseListData['query'] = {
                sortField,
                page,
                status: statuses,
            };

            if (sortOrder) {
                query.sortOrder = sortOrder;
            }

            const { data, error } = await getCustomerCaseList({
                path: { id: this.customerId },
                query,
            });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get cases for customer' });
            }

            if (!data) {
                return;
            }

            this._data = data;
        },
        args: () => [this._page, this._sortField, this._sortOrder, this._statuses] as const,
    });

    onNavigate = (event: PliTabNavigationEvent) => {
        const current = event.detail.current as CaseNavigationItem;
        switch (current) {
            case 'Case history':
                return (this._statuses = [...this._historyStatuses]);
            case 'Active cases':
            default:
                return (this._statuses = [...this._defaultStatuses]);
        }
    };

    onSort = (event: PliTableSortEvent) => {
        const { field, order } = event.detail;
        this._sortField = field as GetCustomerCaseList_SortField;
        this._sortOrder = order;
    };

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    render() {
        const { onNavigate, onSort, _navigationItems } = this;
        const headerItems = defineHeaderItems({
            Id: {
                sortField: null,
                columnSpan: 1,
            },
            Name: {
                sortField: null,
                columnSpan: 2,
            },
            Status: {
                sortField: null,
                columnSpan: 3,
            },
            Created: {
                sortField: customerCaseSortableFields.enum.Created,
                columnSpan: 4,
            },
            Assigned: {
                sortField: null,
                columnSpan: 2,
            },
        });

        const renderTemplate = (item: GetCustomerCaseList_ItemResult): TemplateResult => {
            const assigned: PliUserBubbleProps[] =
                item.assigned?.map((i) => ({
                    firstName: i.firstName ?? '',
                    lastName: i.lastName ?? '',
                    userId: i.userId ?? '',
                })) ?? [];

            return html` <tr>
                <td>${item.caseId}</td>
                <td>
                    <a
                        data-link="navigate"
                        href="${`customers/${item.customerId}/cases/${item.caseId}/#investigations`}"
                        >${item.name}</a
                    >
                </td>
                <td>${item.status ? html`<pli-status-label variant="${item.status}"></pli-status-label>` : null}</td>
                <td>${item.created ? dateTimeFormatter(item.created) : null}</td>
                <td>
                    <pli-user-bubble-list .items="${assigned}"></pli-user-bubble-list>
                </td>
            </tr>`;
        };

        return html`<pli-card>
            <pli-tab-navigation .items="${_navigationItems}" @navigate="${onNavigate}"></pli-tab-navigation>
            <pli-pager
                .items="${this._data?.list ?? []}"
                page="${this._page}"
                total="${this._total}"
                @page-update="${this._paginationHandler}"
                ><pli-table
                    @sort="${onSort}"
                    .headerItems="${headerItems}"
                    .items="${this._data?.list ?? []}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table
            ></pli-pager>
        </pli-card>`;
    }
}

const alertsNavigationItems = ['Active Alerts', 'Alert history'] as const;
type AlertNavigationItem = (typeof alertsNavigationItems)[number];

@customElement('customer-details-investigations-alerts')
class CustomerDetailsInvestigationsAlerts extends LitElement {
    static styles = [styles.grid, styles.flex, css``];

    @property()
    customerId: GetCustomer_Result['customerId'] = '';

    @consume({ context: errorDialogContext })
    errorDialog!: ErrorDialogController;

    data: ListResultOfItemResult8 | null = null;

    _navigationItems = alertsNavigationItems;
    _items: GetCustomerAlertListResponse['list'] = [];
    _defaultStatuses: AlertStatus[] = ['Escalated', 'New', 'Opened'];
    _historyStatuses: AlertStatus[] = ['Closed', 'FalsePositive'];

    @state()
    _statuses: AlertStatus[] = [...this._defaultStatuses];
    @state()
    _page = 1;
    @state()
    _total = 1;
    @state()
    _sortField: GetCustomerAlertList_SortField = 'Created';
    @state()
    _sortOrder: SortableOrder | null = null;

    _task = new Task(this, {
        task: async ([page, sortField, sortOrder, statuses]) => {
            const query: GetCustomerAlertListData['query'] = {
                page,
                status: statuses,
                sortField: sortField,
            };

            if (sortOrder) {
                query.sortOrder = sortOrder;
            }

            const { data, error } = await getCustomerAlertList({
                path: { id: this.customerId },
                query,
            });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get alerts for customer' });
            }

            if (!data) {
                return;
            }

            this.data = data;
        },
        args: () => [this._page, this._sortField, this._sortOrder, this._statuses] as const,
    });

    onPaginationChanged = (event: PliPaginationChangeEvent) => {
        const { page } = event.detail;
        this._page = page;
    };

    onNavigate = (event: PliTabNavigationEvent) => {
        const current = event.detail.current as AlertNavigationItem;
        switch (current) {
            case 'Alert history':
                return (this._statuses = [...this._historyStatuses]);
            case 'Active Alerts':
            default:
                return (this._statuses = [...this._defaultStatuses]);
        }
    };

    onSort = (event: PliTableSortEvent) => {
        const { field, order } = event.detail;
        this._sortField = field as GetCustomerAlertList_SortField;
        this._sortOrder = order;
    };

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    render() {
        const { onNavigate, onSort, _navigationItems } = this;
        const headerItems = defineHeaderItems({
            Id: {
                sortField: null,
                columnSpan: 1,
            },
            Rule: {
                sortField: customerAlertSortableFields.enum.AlertId,
                columnSpan: 3,
            },
            Status: {
                sortField: null,
                columnSpan: 2,
            },
            Created: {
                sortField: customerAlertSortableFields.enum.Created,
                columnSpan: 4,
            },
            Assigned: {
                sortField: null,
                columnSpan: 2,
            },
        });

        const renderTemplate = (item: GetCustomerAlertList_ItemResult): TemplateResult => {
            const assigned: PliUserBubbleProps[] =
                item.assigned?.map((i) => ({
                    firstName: i.firstName ?? '',
                    lastName: i.lastName ?? '',
                    userId: i.userId ?? '',
                })) ?? [];

            return html` <tr>
                <td>${item.alertId}</td>
                <td>
                    <a
                        data-link="navigate"
                        href="${`customers/${item.customerId}/alerts/${item.alertId}/#investigations`}"
                        >${item.rule?.name}</a
                    >
                </td>
                <td>${item.status ? html`<pli-status-label variant="${item.status}"></pli-status-label>` : null}</td>
                <td>${item.created ? dateTimeFormatter(item.created) : null}</td>
                <td>
                    <pli-user-bubble-list .items="${assigned}"></pli-user-bubble-list>
                </td>
            </tr>`;
        };

        return html`<pli-card>
            <pli-tab-navigation .items="${_navigationItems}" @navigate="${onNavigate}"></pli-tab-navigation>
            <pli-pager
                .items="${this.data?.list ?? []}"
                page="${this._page}"
                total="${this._total}"
                @page-update="${this._paginationHandler}"
                ><pli-table
                    @sort="${onSort}"
                    .headerItems="${headerItems}"
                    .items="${this.data?.list ?? []}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table
            ></pli-pager>
        </pli-card>`;
    }
}
