import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import 'shared/comments/comments-area';

import {
    addCustomerComment,
    archiveCustomerComment,
    GetCustomer_Result,
    getCustomerCommentList,
    GetCustomerCommentList_ItemResult,
    updateCustomerComment,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('customer-details-comments')
class CustomerDetailsComments extends LitElement {
    @property()
    customerId!: GetCustomer_Result['customerId'];

    @consume({ context: errorDialogContext, subscribe: true })
    errorDialog!: ErrorDialogController;

    @state()
    _value: string | null = null;

    @state()
    _comments: GetCustomerCommentList_ItemResult[] = [];

    _task = new Task(this, {
        task: async ([]) => {
            await this.load();
        },
        args: () => [] as const,
    });

    private async load(): Promise<void> {
        const { data, error } = await getCustomerCommentList({ path: { id: this.customerId } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not load comments' });
        }
        this._comments = data?.list ?? [];
    }

    private async onAdd(event: CustomEvent<{ value?: string }>): Promise<void> {
        const { error } = await addCustomerComment({
            path: { id: this.customerId },
            body: { comment: event.detail.value ?? '' },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not add comment to customer' });
        }

        await this.load();
    }

    private async onEdit(event: CustomEvent<{ value: string | null; commentId: number }>): Promise<void> {
        const { value, commentId } = event.detail;

        const { error } = await updateCustomerComment({
            path: { commentId, id: this.customerId },
            body: { comment: value ?? '' },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not update comment' });
        }

        await this.load();
    }

    private async onReply(event: CustomEvent<{ value: string | null; parentId: number }>): Promise<void> {
        const { value, parentId } = event.detail;

        const { error } = await addCustomerComment({
            path: { id: this.customerId },
            body: { comment: value ?? '', parentId },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not reply to comment' });
        }

        await this.load();
    }

    private async onDelete(event: CustomEvent<{ value: number }>): Promise<void> {
        const { value } = event.detail;
        const { error } = await archiveCustomerComment({
            path: { commentId: value, id: this.customerId },
            parseAs: 'stream',
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not delete comment' });
        }

        await this.load();
    }

    private async onCancel() {
        await this.load();
    }

    render(): TemplateResult {
        return html`
            <comments-area
                .comments="${this._comments}"
                @add="${this.onAdd}"
                @edit="${this.onEdit}"
                @reply="${this.onReply}"
                @delete="${this.onDelete}"
                @cancel="${this.onCancel}"
            >
                <div class="grid-vertical gap-05">
                    <pli-text as="h2" variant="h2">Comments</pli-text>
                    <pli-text as="p">Share your notes and thought process</pli-text>
                </div>
            </comments-area>
        `;
    }
}
