import { autoinject } from 'aurelia-framework';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class AlertDetails {
    alertId: string;

    async activate(params) {
        params = cleanParamsObject(params);
        this.alertId = params.id;
    }
}
