import { Router, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { OpenIdConnectRoles } from 'aurelia-open-id-connect';

export class Customers {
    public router: Router;

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.map([
            {
                route: '',
                name: 'customers',
                moduleId: PLATFORM.moduleName('./list/customers-list'),
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: '/:id',
                name: 'customer-details',
                moduleId: PLATFORM.moduleName('./details/customer-details'),
                title: 'Customer',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
        ]);

        this.router = router;
    }
}
